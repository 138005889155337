

.matrix-dynamic-conditional button.add-row-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #4975B6;
  background: transparent;
  cursor: pointer;
  transition: all 150ms;
}

.matrix-dynamic-conditional table {
  width: 100%;
}

.matrix-dynamic-conditional table th {
  font-weight: normal;
  text-align: left;
  vertical-align: bottom;
}

.matrix-dynamic-conditional table td {
  vertical-align: top;
}

.matrix-dynamic-conditional table tr td.sd-table__cell:not(.sd-table__cell--empty):not(.sd-table__cell--actions):not(:empty){
  min-width: auto;
}


.matrix-dynamic-conditional button.add-row-button:focus {
  outline: none;
}