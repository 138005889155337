
table {
  width: 100%;

  th {
    font-weight: normal;
    text-align: left;
    vertical-align: bottom;
  }

  td {
    vertical-align: top;
  }
}

.matrix-dynamic-styled button.add-row-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: #4975B6;
  background: transparent;
  cursor: pointer;
  transition: all 150ms;
}

.matrix-dynamic-styled table {
  width: 100%;
}

.matrix-dynamic-styled table th {
  font-weight: normal;
  text-align: left;
  vertical-align: bottom;
}

.matrix-dynamic-styled table td {
  vertical-align: top;
}

.matrix-dynamic-styled button.add-row-button:focus {
  outline: none;
}

.matrix-dynamic-styled button.remove-row-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
  transition: all 150ms;
}

.matrix-dynamic-styled button.remove-row-button:focus {
  outline: none;
}