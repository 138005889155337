.survey-dialog-container .sd-table__cell>.sd-table__question-wrapper .sd-dropdown__value .sv-string-viewer,
.survey-dialog-container .sd-table__cell>.sd-table__question-wrapper .sd-dropdown__value .sd-dropdown__hint-suffix {
    position: absolute;
    left: 0;
    width: 100%;
}

.survey-dialog-container .sd-table-wrapper:after {
    background-color: transparent !important;
}

.survey-dialog-container .sd-table-wrapper:before {
    background-color: transparent !important;
}

:root {
    .survey-dialog-container .survey-wrapper .sd-root-modern {
        --base-vertical-padding: 20px;
        --sd-base-padding: 10px !important;
    }
}

.survey-dialog-container .survey-wrapper .sd-root-modern {
    background-color: var(--indigo-25) !important;
}

.survey-dialog-container .survey-wrapper .sd-panel.sd-row--multiple.sd-row--compact>div:not(:first-child) {
    padding-left: 20px;
}

.survey-dialog-container .survey-wrapper .sd-element--with-frame.sd-element--compact {
    background-color: white;
    border: none;
    padding: 20px;
}

.survey-dialog-container .survey-wrapper .sd-panel__header.sd-element__header.sd-element__header--location-top:after {
    width: 0
}

.survey-dialog-container .survey-wrapper .sd-element--complex.sd-element--with-frame>.sd-element__header {
    padding-top: 0;
}

.survey-dialog-container .survey-wrapper .nested-panel>.sd-panel__header {
    padding-bottom: var(--base-vertical-padding);
}

.survey-dialog-container .survey-wrapper .sd-question--table>.sd-question__content {
    padding-top: 0;
}

.survey-dialog-container .survey-wrapper .sd-dropdown__list-container {
    position: absolute !important;
    z-index: 1000;
}

.survey-dialog-container .survey-wrapper .sd-dropdown__list {
    max-height: 250px;
    overflow-y: auto;
    background: white;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

.survey-dialog-container .survey-wrapper .material-composition-matrix.sd-question__content thead th {
  padding-left: 10px;
}

.survey-dialog-container .survey-wrapper .material-yarn-suppliers-matrix.sd-question__content thead th {
  padding-left: 10px;
}

.survey-dialog-container .sd-panel:not(.sd-panel--as-page) .sd-row--multiple>div:not(:first-child) {
  padding-left: 14px;
}

.survey-dialog-container  .sd-matrixdynamic__content.sd-question--table.sd-element--nested>.sd-question__content, .sd-question--table:not(.sd-element--with-frame):not(.sd-element--nested) {
  padding-left: 0;
  padding-right: 0;
}

.survey-dialog-container .sd-question--table.sd-element--nested>.sd-question__content, .sd-question--table:not(.sd-element--with-frame):not(.sd-element--nested){
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}

.survey-dialog-container .sd-matrixdynamic__content .sd-table-wrapper {
  display: block;
  margin: 0;
  width: 100%;
  min-width: auto;
}

.survey-dialog-container .sd-element--with-frame.sd-element--compact .sd-matrixdynamic {
  width: 95%;
}