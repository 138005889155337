.pq-bop-t2-relevant-process{
  padding-left: 10%;
}

@media screen and (max-width: 1600px) {
  .pq-bop-t2-relevant-process {
    padding-left: 0;
  }
}

.pq-bop-t2-relevant-process .items-center {
  align-items: normal;
}

.pq-bop-t2-relevant-process .radiogroup button {
  margin-top: 5px;
}

.pq-bop-t2-relevant-process .radiogroup button span {
  align-items: center;
}

.pq-bop-t2-relevant-process .sd-question__header--location--left {
  margin-top: 0;
}