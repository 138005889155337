@tailwind base;
@tailwind components;
@tailwind utilities;
/* cyrillic-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2JL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F,
    U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa0ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2ZL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1,
    U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa2pL7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329,
    U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa25L7W0Q5n-wU.woff2)
    format("woff2");
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF,
    U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 100 900;
  font-display: swap;
  src: url(assets/fonts/UcC73FwrK3iLTeHuS_fvQtMwCp50KnMa1ZL7W0Q5nw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

:root {

  --base-padding: 30px;
  --base-vertical-padding: 25px;

/* COLORS */
  --gray-50: #e8ecee;
  --gray-100: #d0d9dd;
  --gray-200: #b8c6cc;
  --gray-300: #a0b3ba;
  --gray-400: #718d98;
  --gray-500: #5e7882;
  --gray-600: #4d626a;
  --gray-700: #3c4c53;
  --gray-800: #2b363b;
  
  --success-300: #6CE9A6;
  --success-500: #12b76a;

  --warning-100: #FEF0C7;
  --warning-300: #fec84b;
  --warning-600:#DC6803;

  --error-300: #FDA29B;
  --error-500: #f04438;

  --indigo-25: #F1F4F9;
  --indigo-50: #E2E9F3;
  --indigo-100: #c5d3e8;
  --indigo-200: #A7BDDC;
  --indigo-300: #8AA6D0;
  --indigo-400: #6D90C5;
  --indigo-500: #4975b6;
  --indigo-600: #4067A0;
  --indigo-700: #345483;
  --indigo-800: #294166;
  --indigo-900: #1D2F49;

  --primary-600: #3aa69f;


  --mandarin-25: #FFF0EB;
  --mandarin-50: #FFE0D6;
  --mandarin-600: #FF6333;
  --mandarin-900: #A32600;

  --toastify-color-success: var(--success-500);
  --toastify-color-warning: var(--warning-300);
  --toastify-color-error: var(--error-500);
  --toastify-font-family: "Inter";
  --toastify-text-color-light: var(--gray-800);
}

  @media (max-width:768px) {
      :root {
        --base-vertical-padding: 20px !important;
        --base-padding: 20px !important;
      }
        
      .sd-root-modern.sd-root-modern--mobile .sd-table__cell--row-text:not(.sd-matrix__cell){
        color: var(--gray-800) !important
      }

      .sd-table__cell {
        font-size: 12px !important;
      }

      .sd-table__cell--header span {
        font-size: 12px !important;
      }
    }

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast-body {
  align-items: start;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.sd-root-modern {
  background-color: white !important;
}

.sd-panel__content .sd-row:not(:first-child) .nested-panel {
    margin-left: calc(-1*var(--base-padding));
    margin-right: calc(-1*var(--base-padding));
    border-top: 1px solid var(--gray-50);
    padding-left: var(--base-padding);
    padding-top: var(--base-padding);
    padding-right: var(--base-padding);
    width: calc(100% +2*var(--base-padding))
}

.nested-panel .sd-element__header::after {
  content: none !important;
}

.sd-table__cell--row-text {
  font-size: 14px !important; 
}

.sd-table__cell--row-text, .sd-table__cell--column-title {
  color: var(--gray-700)
}

.sd-panel__description{
  font-size: 12px;
  font-weight: 400;
  color: var(--gray-700);
  line-height: 14px;
}
