.bill-of-processes .sd-table.sd-matrixdynamic {
  padding-top: 10px;
}
.bill-of-processes .sd-table.sd-matrixdynamic thead {
  display: none;
}

.bill-of-processes .garment-print-subcontractor {
  padding-right: 28px;
}

.bill-of-processes .garment-dyeing-subcontractor {
  padding-right: 15px;
}

.bill-of-processes .sd-question__content #selected-value {
  color: theme("colors.gray.500");
}

.bill-of-processes .sd-panel__content {
  padding-top: 0;
}
